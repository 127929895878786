import React, { useState, useEffect } from 'react';
import KeplerGl from "kepler.gl";
import { addDataToMap, togglePerspective, addFilter, setFilter, removeFilter, enlargeFilter } from "kepler.gl/actions";
import KeplerGlSchema from 'kepler.gl/schemas';
import axios from 'axios';
import { useDispatch, connect } from "react-redux";
import PropTypes from 'prop-types';

var map;
var apiFetchData;
const Map = (props) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);


    const addFirstJson = () => {
                // map.addLayer(
        //   {
        //   'id': 'mapillary',
        //   'type': 'line',
        //   'source': 'composite',
        //   'source-layer': 'water',
        //   'layout': {
        //   'line-cap': 'round',
        //   'line-join': 'round'
        //   },
        //   'paint': {
        //   'line-opacity': 0.6,
        //   'line-color': 'rgb(53, 175, 109)',
        //   'line-width': 2
        //   }
        //   },
        //   'waterway-label'
        //   );

        map.addLayer(
          {
            'id': 'earthquakes-heat',
            'type': 'heatmap',
            'source': 'composite',
            'source-layer': 'water',
            'maxzoom': 9,
            'paint': {
              // Increase the heatmap weight based on frequency and property magnitude
              'heatmap-weight': [
                'interpolate',
                ['linear'],
                ['get', 'count'],
                0,
                0,
                6,
                1,
              ],
              // Increase the heatmap color weight weight by zoom level
              // heatmap-intensity is a multiplier on top of heatmap-weight
              'heatmap-intensity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                1,
                9,
                3,
              ],
              // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
              // Begin color ramp at 0-stop with a 0-transparancy color
              // to create a blur-like effect.
              'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(33,102,172,0)',
                0.2,
                'rgb(103,169,207)',
                0.4,
                'rgb(209,229,240)',
                0.6,
                'rgb(253,219,199)',
                0.8,
                'rgb(239,138,98)',
                1,
                'rgb(178,24,43)',
              ],
              // Adjust the heatmap radius by zoom level
              'heatmap-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                5,
                15,
                40,
              ],
              // Transition from heatmap to circle layer by zoom level
              'heatmap-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                7,
                1,
                9,
                0,
              ],
            },
          },
          'waterway-label'
        );

    }
    const onLoadMap = () => {
      map.on('load', function () {
          // addFirstJson();

          // setTimeout(() => {
            // alert(1);
            // var filter = dispatch(addFilter('covid19'));
            // dispatch(setFilter(0, "name", "country"));
          // }, 100);
      });
    }

    const onChangeRadioBtn = (fo) => {
      let layer = map.getLayer("earthquakes-heat");
      let rangeDiv = document.getElementById("range");
      rangeDiv.style.display = 'none';
      console.log("config: ", props.keplerGl.covid);
      if(fo === '1'){
        dispatch(enlargeFilter(0));
        dispatch(removeFilter(0))
        map.setLayoutProperty(layer.id, 'visibility', 'visible');
      }
      if(fo === '2'){
        const configToSave = KeplerGlSchema.getConfigToSave(props.keplerGl.covid);
        var filter = dispatch(addFilter('covid19'));
        dispatch(setFilter(0, "name", "day"));
        // dispatch(togglePerspective());
      }
      if(fo === '3'){
        rangeDiv.style.display = 'block';
        map.setLayoutProperty(layer.id, 'visibility', 'none');
      }

    }
    const createRadioBtn = () => {
      var html = `<label class="custom-radio-btn-container">Json First
        <input type="radio" checked="checked" class="radio-btn-for-json" name="jsonRadio" value="1" checked />
        <span class="checkmark"></span>
      </label>
      <label class="custom-radio-btn-container">Json Second
        <input type="radio" class="radio-btn-for-json" name="jsonRadio" value="2" />
        <span class="checkmark"></span>
      </label>
      <label class="custom-radio-btn-container">Json Third
        <input type="radio" class="radio-btn-for-json" name="jsonRadio" value="3" />
        <span class="checkmark"></span>
      </label>`;
      // var html = `<input type="radio" class="radio-btn-for-json" id="male" name="jsonRadio" value="1" checked />
      // <label class="styled-label" for="1">Json One</label><br>
      // <input type="radio" class="radio-btn-for-json" id="female" name="jsonRadio" value="2">
      // <label class="styled-label" for="female">Json Two</label><br>
      // <input type="radio" class="radio-btn-for-json" id="other" name="jsonRadio" value="3">
      // <label class="styled-label" for="other">Json Third</label>`;
      var el = document.getElementsByClassName('layer-manager')[0];
      var div = document.createElement('div');
      div.id = 'radioBtnWrapper';
      div.innerHTML = html;
      div.className = 'text-primary';

      el.parentNode.appendChild(div);
      document.querySelectorAll('.radio-btn-for-json').forEach(item => {
        item.addEventListener('change', event => {
          onChangeRadioBtn(item.getAttribute('value'));
        })
      })
    }

    const getData = async () => {
      const resp = await axios.get("./convertcsv.json");
      // const resp = await axios.get("https://gist.githubusercontent.com/leighhalliday/a994915d8050e90d413515e97babd3b3/raw/a3eaaadcc784168e3845a98931780bd60afb362f/covid19.json");
      let responseData = resp.data;
      apiFetchData = responseData;
      setData(responseData);
    }

    useEffect(() => {
      getData();
    }, []);
  
    
    useEffect(() => {
        if(data.length === 0)
        return false;
        var sampleDataArr = [];
        data.map((obj)=> {
          if(obj.PlantDate === ''){
            return;
          }
          const [dateValues, timeValues] = (obj.PlantDate).split(' ');
          // console.log(dateValues); // 👉️ "09/24/2022"
          // console.log(timeValues); // 👉️ "07:30:14"
          // obj.PlantDate
          sampleDataArr.push([
              obj.Age,
              obj.DBH,
              obj.Plan,
              Date.parse(dateValues),
              obj.PlantType,
              obj.SiteOrder,
              obj.TreeID,
              obj.latitude,
              obj.longitude,
              obj.qAddress,
              obj.qSiteInfo,
              obj.qSpecies,
            ]);
          })
        var sampleData = {
          fields: [
            {name: 'Age', format: '', type: 'integer'},
            {name: 'DBH', format: '', type: 'integer'},
            {name: 'Plan', format: '', type: 'integer'},
            {name: 'PlantDate', format: 'YYYY-M-D H:m:s', type: 'timestamp'},
            {name: 'PlantType', format: '', type: 'real'},
            {name: 'SiteOrder', format: '', type: 'integer'},
            {name: 'TreeID', format: '', type: 'integer'},
            {name: 'latitude', format: '', type: 'real'},
            {name: 'longitude', format: '', type: 'real'},
            {name: 'qAddress', format: '', type: 'string'},
            {name: 'qSiteInfo', format: '', type: 'string'},
            {name: 'qSpecies', format: '', type: 'string'},
          ],
          rows: sampleDataArr
         };
      //    var sampleConfig = {
      //     "visState": {
      //       "filters": [],
      //       "layers": [
      //         {
      //           "id": "mzprg2",
      //           "type": "hexagon",
      //           "config": {
      //             "dataId": "sftrees",
      //             "label": "Hexagon",
      //             "color": [
      //               119,
      //               110,
      //               87
      //             ],
      //             "columns": {
      //               "lat": "latitude",
      //               "lng": "longitude"
      //             },
      //             "isVisible": true,
      //             "visConfig": {
      //               "opacity": 0.8,
      //               "worldUnitSize": 0.05,
      //               "resolution": 8,
      //               "colorRange": {
      //                 "name": "Purple Blue Yellow",
      //                 "type": "sequential",
      //                 "category": "Uber",
      //                 "colors": [
      //                   "#2B1E3E",
      //                   "#383C65",
      //                   "#3E5F7E",
      //                   "#49838A",
      //                   "#5EA28D",
      //                   "#82BB92",
      //                   "#AECEA1",
      //                   "#D6DEBF"
      //                 ],
      //                 "reversed": false
      //               },
      //               "coverage": 1,
      //               "sizeRange": [
      //                 0,
      //                 500
      //               ],
      //               "percentile": [
      //                 0,
      //                 99.94
      //               ],
      //               "elevationPercentile": [
      //                 0,
      //                 100
      //               ],
      //               "elevationScale": 5,
      //               "hi-precision": false,
      //               "colorAggregation": "average",
      //               "sizeAggregation": "average",
      //               "enable3d": true
      //             }
      //           },
      //           "visualChannels": {
      //             "colorField": null,
      //             "colorScale": "quantize",
      //             "sizeField": null,
      //             "sizeScale": "linear"
      //           }
      //         }
      //       ],
      //       "interactionConfig": {
      //         "tooltip": {
      //           "fieldsToShow": {
      //             "sftrees": [
      //               "TreeID",
      //               "qSpecies",
      //               "qAddress",
      //               "SiteOrder",
      //               "qSiteInfo"
      //             ]
      //           },
      //           "enabled": true
      //         },
      //         "brush": {
      //           "size": 0.5,
      //           "enabled": false
      //         }
      //       },
      //       "layerBlending": "normal",
      //       "splitMaps": []
      //     },
      //     "mapState": {
      //       "bearing": -0.6067415730337057,
      //       "dragRotate": true,
      //       "latitude": 37.75898064019907,
      //       "longitude": -122.44780065592347,
      //       "pitch": 1.7944134937952951,
      //       "zoom": 12.509585911862231,
      //       "isSplit": false
      //     },
      //     "mapStyle": {
      //       "styleType": "muted_night",
      //       "topLayerGroups": {},
      //       "visibleLayerGroups": {
      //         "label": false,
      //         "road": false,
      //         "border": false,
      //         "building": true,
      //         "water": true,
      //         "land": true
      //       }
      //     }
      // }
      //    dispatch(
      //     addDataToMap({
      //       datasets: {
      //         info: {
      //           label: "COVID-19",
      //           id: "covid19"
      //         }, 
      //         data: sampleData
      //       },
      //       option: {
      //         centerMap: true,
      //         readOnly: false,
      //       },
      //       config: sampleConfig
      //     })
      //   );
        // console.log(props.keplerGl.visState.layers);

        dispatch(
          addDataToMap({
            datasets: {
              info: {
                label: "COVID-19",
                id: "covid19"
              }, 
              data: sampleData
            },
            option: {
              centerMap: true,
              readOnly: false,
            },
            color: '#ffffff',
            config: {
              mapStyle: {styleType: 'dark'}
            }
          })
        );
    }, [dispatch, data]);
  
    const onChangeRange = (e) => {
      const { value } = e.target;
      let updateStateData = apiFetchData.rows.filter(item => item[5] > value);
      setData({
        ...data, rows: updateStateData
      });
    }
    return (
      <>
      <KeplerGl
        id="covid"
        mapboxApiAccessToken={'pk.eyJ1IjoiYXRvbWljZ3JvdXAiLCJhIjoiY2tsa21xN3ZwMDRmeDJ2bjR3NjloaDQxZCJ9.0pmL16fQeN4HGAUUheY91g'}
        width={window.innerWidth}
        height={window.innerHeight}
        getMapboxRef={ (mapRef) => {
          if(map === undefined)
          map = mapRef.getMap();
          onLoadMap();
          // createRadioBtn();
        }}
      />
      <div id="range">
        <label>
          <input type="range" id="range-slider" name="vol" min={0} step={1} max={1000} onChange={onChangeRange} />
        </label>
      </div>
      </>
    );
}

// Map.propTypes = {
//   setAlert:PropTypes.func.isRequired,
//   register:PropTypes.func.isRequired,
//   isAuthenticated: PropTypes.bool
// };

const mapStateToProps = state => ({
  keplerGl: state.keplerGl
});

export default connect(mapStateToProps, { 
  // setAlert, register
 })(Map);
import React from "react";
import keplerGlReducer from "kepler.gl/reducers";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import { taskMiddleware } from "react-palm/tasks";
import { Provider } from "react-redux";
import Map from './components/Map';


import {handleActions} from 'redux-actions';
import {ActionTypes} from 'kepler.gl/actions';


import './App.css';


const customKeplerReducer = keplerGlReducer.initialState({
  uiState: {
      currentModal: null,
      activeSidePanel: 'filter'
  }
});

// const customKeplerReducer = keplerGlReducer.initialState({
//   uiState: {
//     // hide side panel to disallow user customize the map
//     readOnly: true,
//     // customize which map control button to show
//     mapControls: {
//       visibleLayers: {
//         show: false
//       },
//       mapLegend: {
//         show: true,
//         active: true
//       },
//       toggle3d: {
//         show: false
//       },
//       splitMap: {
//         show: false
//       }
//     }
//   }
// });

const appReducer = handleActions({
  // listen on kepler.gl map update action to store a copy of viewport in app state
  [ActionTypes.ADD_FILTER]: (state, action) => {
    console.log(action);
    return ({
    ...state,
    viewport: action.payload
  }) 
},
}, {});

const reducers = combineReducers({
  keplerGl: customKeplerReducer,
  app: appReducer,
});


const initialState = {};
const store = createStore(reducers, initialState, composeWithDevTools(applyMiddleware(taskMiddleware)));

export default function App() {
  return (
    <Provider store={store}>
      <Map />
    </Provider>
  );
}